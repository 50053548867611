<template>
  <div style="background-color: white;">
    <template>
      <ChatComponent />
      <v-img src="../../assets//img/Vector6.png">
        <v-row class="pb-3" v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
          <v-col cols="12" lg="8" md="8" sm="8" class="pr-0">
            <v-row class="margen-arriba" style="margin-left: 90px;">
              <v-col cols="12" lg="12" style="margin-bottom: 56px;">
                <v-card-title class="text-h2 font-weight-bold text-primary-color text-center text-md-start" style="word-break: break-word;">
                  OFERTAS DE EMPLEOS
                </v-card-title>
              </v-col>
              <v-col cols="12" lg="10" md="12" sm="12" xl="9">
                <v-sheet elevation="0" max-height="65" style="border-radius: 50px;" color="white">
                  <v-row>
                    <v-col cols="6" sm="9" md="9" lg="9" xl="9" class="pr-0">
                      <v-text-field flat rounded solo v-model="search.categoria"
                                    prepend-inner-icon="mdi-briefcase-outline" placeholder="Categoría"
                                    clearable @click:clear="getListOfertasEmpleos()"
                                    class="ml-5 pt-0">

                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3" md="2" lg="3" xl="3" class="pl-0 pt-0 pr-0">
                      <v-btn dark rounded @click="buscar(search)" style="text-transform: none;"class="mt-3" color="#22234a">Buscar empleo
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="12" xl="2" style="margin-left: -180px;" v-if="$vuetify.breakpoint.lg">
            <div class="d-flex justify-start align-center ml-5 pl-0">
              <v-img src="../../assets/img/empleos.svg" max-height="620" max-width="550">

              </v-img>

            </div>
          </v-col>
          <v-col cols="12" lg="2" md="2" sm="12" xl="2" style="margin-left: -320px;" v-else-if="$vuetify.breakpoint.xl">
            <div class="d-flex justify-start align-center ml-5 pl-0">
              <v-img src="../../assets/img/empleos.svg" max-height="1000" max-width="750">

              </v-img>
            </div>

          </v-col>
          <v-col cols="12" lg="2" md="2" sm="2" xl="2" style="margin-left: -180px;" v-else-if="$vuetify.breakpoint.md">
            <div class="d-flex justify-start align-center ml-5 pl-0">

              <v-img src="../../assets/img/empleos.svg" max-width="449">

              </v-img>
            </div>

          </v-col>

        </v-row>
        <v-row v-else>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="12" class="pb-0 d-flex justify-center justify-md-start">
                <v-card-title class="text-h4 font-weight-bold text-primary-color text-center text-md-start" style="word-break: break-word;">
                  OFERTAS DE EMPLEOS
                </v-card-title>
              </v-col>
              <v-col cols="12" sm="12" class="pb-0 pt-0">
                <v-img src="../../assets/img/empleos.svg">

                </v-img>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-sheet elevation="0" max-height="65" style="border-radius: 50px;" color="white">
                  <v-row>
                    <v-col cols="12" class="pr-0">
                      <v-text-field flat rounded solo v-model="search.categoria"
                                    prepend-inner-icon="mdi-briefcase-outline" placeholder="Categoría"
                                    clearable @click:clear="getListOfertasEmpleos()" append-icon="mdi-magnify"
                                    @click:append="buscar(search)"
                                    class="ml-5 pt-0">

                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-img>
    </template>

    <v-row class="ml-3 mr-3">
      <!--Seccion filtros -->
      <v-col cols="12" lg="3" md="3" class="mt-15" v-if="$vuetify.breakpoint.width > 970">
        <v-card flat>
          <v-row style="margin-right: 0 !important;">
            <v-col cols="2" lg="2">
              <v-btn rounded large class="my-button" outlined @click="regresar()">
                <v-icon>mdi-keyboard-backspace</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10" lg="10">
              <strong class="text-h4 font-weight-bold ml-10 mt-4">Filtros</strong>
            </v-col>

          </v-row>
          <v-row class="mt-10">
            <v-col cols="12" sm="12">
              <v-text-field rows="1" label="Palabra clave" append-icon="mdi-magnify" outlined rounded
                            v-model="search.palabra" @input="buscar(search)" clearable>

              </v-text-field>

              <v-autocomplete outlined rounded placeholder="Área de intéres" v-model="search.area_interes"
                              :items="areaInteres" item-value="id" clearable item-text="nombre_area"
                              @change="buscar(search)">
              </v-autocomplete>
              <br>

              <v-card-title class="font-weight-bold text-h5">
                Fecha
              </v-card-title>

              <!-- <v-checkbox v-for="(item) in cheackFechas" :key="item.id"
                  v-model="fechaSelect"
                  :label="item.nombre"
                 >
                  </v-checkbox> -->

              <v-layout row class="ml-2">
                <!-- <v-flex xs12 sm6 md4 lg6> -->
                <v-flex>
                  <v-checkbox v-for="(item) in cheackFechas" :key="item.id"
                              v-model="search.fecha_publicacion" :label="item.nombre" hide-details
                              @change="buscar(search)" :value="item.id">
                  </v-checkbox>
                </v-flex>
              </v-layout>

              <v-card-title class="font-weight-bold text-h5 mt-15">
                Experiencia
              </v-card-title>

              <v-layout row class="ml-2">
                <!-- <v-flex xs12 sm6 md4 lg6> -->
                <v-flex>
                  <v-checkbox v-for="(item) in cheackExperiencia" :key="item.id"
                              v-model="search.nivel_experiencia" :label="item.anios" hide-details :value="item.id"
                              @change="buscar(search)">
                  </v-checkbox>
                </v-flex>
              </v-layout>

              <v-card-title class="font-weight-bold text-h5 mt-15">
                Discapacidad
              </v-card-title>


              <v-layout row class="ml-2">
                <!-- <v-flex xs12 sm6 md4 lg6> -->
                <v-flex>
                  <v-checkbox v-for="(item) in cheackDiscapacidad" :key="item.id"
                              v-model="search.discapacidad" :label="item.nombre" hide-details :value="item.id"
                              @change="buscar(search)">
                  </v-checkbox>
                </v-flex>
              </v-layout>


              <v-card-title class="font-weight-bold text-h5 mt-15">
                Tipo de horario
              </v-card-title>

              <v-layout row class="ml-2">
                <!-- <v-flex xs12 sm6 md4 lg6> -->
                <v-flex>
                  <v-checkbox v-for="(item) in cheackHorario" :key="item.id" v-model="search.tipo_horario"
                              :label="item.nombre" hide-details :value="item.id" @change="buscar(search)">
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col v-else cols="12" class="mt-12 centra-movil">
        <v-btn rounded large class="my-button" outlined @click="abrirFiltros()">Filtrar</v-btn>
      </v-col>

      <!--Seccion ofertas de empleo -->
      <v-col cols="12" lg="8" sm="12" md="8">
        <!-- Sugerencias de empleos-->
        <template>
          <v-card flat>
            <v-toolbar-title class="mt-5 mb-5 text-wrap  d-flex justify-center justify-md-start" style="color: #1c1e4d;">
              <div class="text-h4 font-weight-bold text-center text-md-start">
                <b>Sugerencias de empleo</b>
              </div>
            </v-toolbar-title>

            <div :class="$vuetify.breakpoint.xs ? 'margin-top-50' : 'postulaciones-container'" v-if="token">
              <div class="postulaciones" v-for="(postulacion) in ListOfertaEmplosSugeridos"
                   :key="postulacion.id">
                <CardEmpleo :item="postulacion" :favoritos="true" :persona="persona"
                            v-on:actualizarOfertas="actualizarCards"
                            v-on:favorito-cambiado="handleFavorito"
                            @on-click-detalle="goToDetalle"/>
              </div>
            </div>

            <div v-if="!token" class="centra-movil">
              <p class="text-h6">Para acceder a las mejores oportunidades de empleo personalizadas y disfrutar de todas
                las ventajas de nuestra
                plataforma, te invitamos a <a target="_blank" style="color: #1c1e4d;" class="font-weight-bold" @click="
                    goToIdentidadDigital(
                      `${BASE_URL_ID}/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL.replace(
                        '/',
                        '%2F'
                      )}`
                    )"><u>registrarte</u></a> o
                <a @click="iniciarSesion()" class="font-weight-bold" style="color: #1c1e4d;" target="_blank"><u>iniciar
                  sesión</u></a>. Con una cuenta, podrás crear
                un perfil profesional,recibir sugerencias de empleo específicas para ti y mucho más.
              </p>
            </div>
          </v-card>
        </template>

        <!-- ofertas  de empleos-->
        <template>
          <v-card flat>
            <v-toolbar-title class="mt-15 text-wrap d-flex justify-center justify-md-start" style="color: #1c1e4d;">
              <div class="text-h4 font-weight-bold text-center text-md-start">
                <b>Empleos disponibles
                  <v-chip large class="text-h6 font-weight-bold">{{ ListOfertaEmplos.length }}</v-chip>
                </b>
              </div>
            </v-toolbar-title>

            <div :class="$vuetify.breakpoint.xs ? 'margin-top-50' : 'postulaciones-container'">
              <div class="postulaciones" v-for="(postulacion) in ListOfertaEmplos" :key="postulacion.id">
                <CardEmpleo :item="postulacion" :favoritos="false" :persona="persona"
                            v-on:actualizarOfertas="actualizarCards" @on-click-detalle="goToDetalle"/>
              </div>
            </div>
          </v-card>
        </template>
      </v-col>


      <!-- Seccion paginacion -->
      <v-col cols="12" lg="12" md="12" sm="12">
        <div class="text-center ">
          <v-pagination
              :perPage.sync="por_pagina"
              :page.sync="pagina"
              v-model="pagina"
              :length="totalPages"
              class="my-pagination"
              @input="getListOfertasEmpleos"
          ></v-pagination>
        </div>
      </v-col>

    </v-row>

    <v-dialog v-model="modalFiltro" fullscreen>
      <v-card>
        <v-card-title>
          <v-col class="text-primary-color font-weight-bold d-flex justify-center ml-12"
                 style="justify-content: center;">Filtros
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn class="my-button" icon @click="modalFiltro = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-10">
            <v-col cols="12" sm="12">
              <v-text-field rows="1" label="Palabra clave" append-icon="mdi-magnify" outlined rounded
                            v-model="search.palabra" clearable>

              </v-text-field>

              <v-autocomplete outlined rounded placeholder="Área de intéres" v-model="search.area_interes"
                              :items="areaInteres" item-value="id" clearable item-text="nombre_area"
              >
              </v-autocomplete>
              <br>

              <v-card-title class="font-weight-bold text-h5">
                Fecha
              </v-card-title>

              <!-- <v-checkbox v-for="(item) in cheackFechas" :key="item.id"
                  v-model="fechaSelect"
                  :label="item.nombre"
                 >
                  </v-checkbox> -->

              <v-layout row class="ml-2">
                <v-flex xs12 sm6 md4 lg6>
                  <v-checkbox v-for="(item) in cheackFechas" :key="item.id"
                              v-model="search.fecha_publicacion" :label="item.nombre" hide-details
                              :value="item.id">
                  </v-checkbox>
                </v-flex>
              </v-layout>

              <v-card-title class="font-weight-bold text-h5 mt-15">
                Experiencia
              </v-card-title>

              <v-layout row class="ml-2">
                <v-flex xs12 sm6 md4 lg6>
                  <v-checkbox v-for="(item) in cheackExperiencia" :key="item.id"
                              v-model="search.nivel_experiencia" :label="item.anios" hide-details :value="item.id"
                  >
                  </v-checkbox>
                </v-flex>
              </v-layout>

              <v-card-title class="font-weight-bold text-h5 mt-15">
                Discapacidad
              </v-card-title>


              <v-layout row class="ml-2">
                <v-flex xs12 sm6 md4 lg6>
                  <v-checkbox v-for="(item) in cheackDiscapacidad" :key="item.id"
                              v-model="search.discapacidad" :label="item.nombre" hide-details :value="item.id"
                  >
                  </v-checkbox>
                </v-flex>
              </v-layout>


              <v-card-title class="font-weight-bold text-h5 mt-15">
                Tipo de horario
              </v-card-title>

              <v-layout row class="ml-2">
                <v-flex xs12 sm6 md4 lg6>
                  <v-checkbox v-for="(item) in cheackHorario" :key="item.id" v-model="search.tipo_horario"
                              :label="item.nombre" hide-details :value="item.id">
                  </v-checkbox>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="d-flex justify-center align-center my-3">
            <v-btn class="my-button" rounded outlined style="text-transform: none;justify-content: center;"
                   @click="filtrar()">Aplicar filtros
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-loader v-if="loading"></app-loader>
  </div>
</template>


<script>
import CardEmpleo from '../../components/CardEmpleo.vue'
import foorter from '../../components/AppFooter.vue'
import ChatComponent from "../../components/ChatComponent.vue";
import {mapMutations, mapState} from 'vuex'
import jwtDecode from "jwt-decode";
import AppLoader from "@/components/AppLoader.vue";

export default {
  components: {
    CardEmpleo,
    foorter,
    ChatComponent,
    AppLoader
  },
  data() {
    return {
      persona: 8,
      loading: false,
      page: 1,
      empleos: "56",
      fechaSelect: null,
      dataPersona: {},
      token: null,
      por_pagina: null,
      pagina: null,
      paginacion: false,
      totalRegistros: null,

      ListOfertaEmplos: {},
      ListOfertaEmplosSugeridos: [],
      cheackFechas: [
        {id: 1, nombre: "Hoy"},
        {id: 2, nombre: "Últimos 3 días"},
        {id: 3, nombre: "Última semana"},
        {id: 4, nombre: "Últimos 15 días"},
        {id: 5, nombre: "Último mes"}
      ],

      cheackExperiencia: [
        {id: 1, nombre: "Sin experiencia"},
        {id: 2, nombre: "1 año"},
        {id: 3, nombre: "2 años"},
        {id: 4, nombre: "3-4 años"},
        {id: 5, nombre: "Último mes"}
      ],

      cheackDiscapacidad: [
        {id: 1, nombre: "Si"},
        {id: 2, nombre: "No"},
      ],

      cheackHorario: [
        {id: 1, nombre: "Administrativo"},
        {id: 2, nombre: "Turno"},
      ],

      areaInteres: null,
      search: {
        categoria: null,
        palabra: null,
        discapacidad: null,
        tipo_horario: null,
        area_interes: null,
        nivel_experiencia: null,
        fecha_publicacion: null,
      },
      modalFiltro: false,
      REDIRECT_URL: process.env.VUE_APP_ID_REDIRECT_URL,
      BASE_URL_ID: process.env.VUE_APP_IDENTIDAD_DIGITAL_URL,
      CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
    }
  },

  computed: {
    ...mapState(['userInfo']),
    totalPages() {
      let totalPaginas = 0;
      if (this.totalRegistros && this.per_page) {
        totalPaginas = Math.ceil(this.totalRegistros / this.per_page);
      }
      return totalPaginas;
    }
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    // async getPersona() {
    //     const response = await this.services.datosPersona.getDatosPersona(this.persona)
    //     const data = {
    //         // nombres: response.data.primer_nombre + response.data.segundo_nombre
    //         nombres:`${response.data.primer_nombre} ${response.data.segundo_nombre}`,
    //         apellidos:`${response.data.primer_apellido} ${response.data.segundo_apellido ? response.data.segundo_apellido : response.data.apellido_casada}`
    //     }
    //     this.setUserInfo(data)

    // },
    handleFavorito(item){
      //console.log('emit')
    },
    async actualizarCards() {
      this.loading = true;
      try {
        await this.getListOfertasEmpleosSugeridos()
      await this.getListOfertasEmpleos()

      setTimeout(() => {
        this.loading = false;
      }, 500);
      //aqui debemos renderizar
      } catch (error) {
        // console.log("error")
      }

    },
    goToDetalle(id) {
      this.$router.push("/empleo/" + id)
    },

    async getAreaInteres() {
      const response = await this.services.OfertaEmpleo.getAreaInteres()
      if (response.status == 200) {
        this.areaInteres = response.data
        // console.log("respuesta",this.areaInteres)
      }
    },

    async getListOfertasEmpleos() {
      let fav_id_persona = this.dataPersona.id
      let params = {
        per_page: this.per_page,
        page: this.pagina,
        pagination: true
      }
      const response = await this.services.OfertaEmpleo.getOfertasEmpleos(fav_id_persona, params)
      if (response.status == 200) {
        this.ListOfertaEmplos = response.data;
        this.empleos = this.ListOfertaEmplos?.length;
        this.per_page = response.headers.per_page;
        this.totalRegistros = response.headers.total_rows;
        this.pagina = parseInt(response.headers.page);
      }

    },

    async getObtenerPaginacion() {
      let params = {
        per_page: this.por_pagina,
        page: this.pagina,
        pagination: true
      }

      const response = await this.services.OfertaEmpleo.getPaginacionEmpleos(params)

      if (response.status == 200) {
        this.ListOfertaEmplos = response.data;
        this.empleos = this.ListOfertaEmplos?.length;
        this.per_page = response.headers.per_page;
        //this.totalRegistros =response.headers.total_rows;
        this.pagina = parseInt(response.headers.page);
      }
    },

    async getListOfertasEmpleosSugeridos() {

      const response = await this.services.OfertaEmpleo.getOfertasEmpleoSugeridos(this.dataPersona.id)
      this.ListOfertaEmplosSugeridos = response.data
      // console.log("respuesta-seguerido",this.ListOfertaEmplosSugeridos)
    },

    iniciarSesion() {
      this.$router.push({name: "login"})
    },
    async goToIdentidadDigital(url) {
      // console.log(url);
      window.open(url, "_parent");
    },
    async buscar(search) {
      const filter = {}
      let fav_id_persona = this.dataPersona.id
      filter.id_nivel_experiencia = search.nivel_experiencia
      filter.id_tipo_horario = search.tipo_horario
      if (search.discapacidad === 1) {
        filter.discapacidad = true
      } else if (search.discapacidad === 2) {
        filter.discapacidad = false
      } else {
        filter.discapacidad = null
      }
      filter.id_area_interes = search.area_interes
      filter.palabra_clave = search.palabra
      if (search.categoria !== null) filter.nombre_categoria = search.categoria
      switch (search.fecha_publicacion) {
        case 1:
          filter.fecha_publicacion = 'hoy';
          break;
        case 2:
          filter.fecha_publicacion = 'ultimos_3_dias';
          break;
        case 3:
          filter.fecha_publicacion = 'ultima_semana';
          break;
        case 4:
          filter.fecha_publicacion = 'ultimos_15_dias';
          break;
        case 5:
          filter.fecha_publicacion = 'ultimo_mes';
          break;
        default:
          filter.fecha_publicacion = null;
          break;

      }
      filter.per_page = this.por_pagina
      filter.page = this.page
      filter.pagination = true
      const response = await this.services.OfertaEmpleo.getOfertasEmpleos(fav_id_persona, filter)
      this.ListOfertaEmplos = response.data
      this.empleos = this.ListOfertaEmplos?.length > 0 ? this.ListOfertaEmplos?.length : "0"
    },
    regresar() {
      this.$router.push({name: 'inicio'})
    },
    async getNivelExperiencia() {
      const response = await this.services.OfertaEmpleo.getNivelExperiencia()
      this.cheackExperiencia = response.data
    },
    async getTipoHorario() {
      const response = await this.services.OfertaEmpleo.getTipoHorario()
      this.cheackHorario = response.data
    },
    abrirFiltros() {
      this.modalFiltro = true
    },
    filtrar() {
      this.buscar(this.search)
      this.modalFiltro = false
    }
  },
  async created() {
    //  this.getPersona();

    this.token = localStorage.getItem('token')
    if (this.token) {
      const userDecode = jwtDecode(this.token)
      this.dataPersona = userDecode.persona
    }

    await this.getListOfertasEmpleos()
    if (this.dataPersona?.hasOwnProperty('id')) {
      await this.getListOfertasEmpleosSugeridos()
    }
    await this.getAreaInteres()
    await this.getNivelExperiencia()
    await this.getTipoHorario()

  }
}
</script>


<style scoped>
.my-button:hover {
  background-color: #333458;
  color: white;
}

.margen-arriba {
  margin-top: 296px;
}

.wave {
  background: url(../../assets/img/wave.svg);
  width: 100% !important;
  background-size: cover;
}

.postulaciones-container {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-gap: 1.5rem;
  margin-top: 50px;
}
@media (max-width: 1024px) {
  .postulaciones-container {
    grid-template-columns: repeat(1, 100%);
  }
}
@media (max-width: 1330px) {
  .postulaciones-container {
    grid-template-columns:repeat(2, 50%);;
  }
}



.margin-top-50 {
  margin-top: 50px;
}

/* .postulaciones {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  margin-top: 50px;
} */


/* @media (max-width: 1024px) {
  .postulaciones-container {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
} */

.text-primary-color {
  color: #22234a !important;
}

.my-pagination .v-pagination__item {
  background-color: #333458;
  color: #30a4f1;
  border-radius: 100%;
}

.my-pagination .v-pagination__item--active {
  background-color: red;
}
@media (max-width: 600px){
  .centra-movil{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-break: break-word;
  }
}
</style>




